import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { useStaticQuery, graphql, Link } from "gatsby"

import { revealFromTop, revealCloseY } from "./animations"
import { Button } from "../../../anti/buttons/buttons"
import { Image } from "components/anti/image/image"

const DesktopServicesSubmenu = ({
  state,
  setState,
  navExpand,
  lang,
  subData,
}) => {
  const data = useStaticQuery(GET_INVESTOR_NAVBAR)

  const navbarData = data.wordPress.page.investorLandingPage.navbarImage

  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)

  const projects = []

  // Reveal & close animation
  useEffect(() => {
    if (state.isOpen === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if (state.isOpen === true) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, {
        duration: 0,
        opacity: 1,
        height: "28rem",
      })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [state])

  useEffect(() => {
    menuBackground.addEventListener("click", () => setState({ isOpen: false }))

    return () =>
      menuBackground &&
      menuBackground.removeEventListener("click", () =>
        setState({ isOpen: false })
      )
  }, [])

  return (
    <>
      <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
        <div
          className="navbar-submenu-bg"
          ref={el => (menuBackground = el)}
          onClick={setState}
        />
        <div className="navbar-submenu-content" ref={el => (menuContent = el)}>
          <div className="navbar-submenu-collapse pt-md-3">
            <div className="navbar-submenu-collapse-header">
              <button className="btn btn-link" onClick={setState}>
                {lang === "id" ? "Kembali" : "Back"}
              </button>
            </div>
            <div className="navbar-submenu-collapse-content">
              <div className={`row w-100`}>
                <div className="col-md-4">
                  <h2 ref={el => (line1 = el)} className="w-100">
                    {subData.label}
                  </h2>
                  {/* <p ref={el => (line2 = el)}>
                      {lang === "en"
                        ? "Discover BSD City current & future developments"
                        : "Temukan pengembangan BSD City saat ini dan masa datang. "}
                    </p> */}
                  <ul className="navbar-nav">
                    {subData.data
                      ?.slice(0, Math.ceil(subData.data?.length / 2))
                      ?.map((sub, i) => {
                        const num = i + 3
                        let line =
                          num === 3
                            ? line3
                            : num === 4
                            ? line4
                            : num === 5
                            ? line5
                            : num === 6
                            ? line6
                            : num === 7
                            ? line7
                            : num === 8
                            ? line8
                            : ""
                        return (
                          <li key={i} className="nav-item">
                            <div ref={el => (line = el)}>
                              {/* prettier-ignore */}
                              <Link className="nav-link"
                                // activeClassName="active"
                              to={sub.url}>
                              {sub.label}
                            </Link>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul className="navbar-nav">
                    {subData.data
                      ?.slice(-Math.ceil(subData.data?.length / 2 - 1))
                      ?.map((sub, i) => {
                        const num = i + 3
                        let line =
                          num === 3
                            ? line3
                            : num === 4
                            ? line4
                            : num === 5
                            ? line5
                            : num === 6
                            ? line6
                            : num === 7
                            ? line7
                            : num === 8
                            ? line8
                            : ""
                        return (
                          <li key={i} className="nav-item">
                            <div ref={el => (line = el)}>
                              {/* prettier-ignore */}
                              <Link className="nav-link" 
                            // activeClassName="active"
                            to={sub.url}>
                              {sub.label}
                            </Link>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <div className="col-md-4">
                  <Image
                    src={navbarData.image.sourceUrl}
                    ratio="r-16-9"
                    className="img-fluid mb-3"
                    alt="investors"
                  />
                  {/* <img
                    alt="investors"
                    src={investorsImg}
                    className="img-fluid mb-3"
                  /> */}
                  <Button
                    link={
                      lang === "id"
                        ? "/id/investor/investors-relations/"
                        : "/investor/investors-relations/"
                    }
                    variant="primary"
                    className="btn-block mb-3 text-uppercase"
                  >
                    {lang === "id" ? "Hubungan Investor" : "Investor Relations"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopServicesSubmenu

const GET_INVESTOR_NAVBAR = graphql`
  query INVESOTR_NAVBAR {
    wordPress {
      page(id: "investor-landing", idType: URI) {
        investorLandingPage {
          navbarImage {
            image {
              sourceUrl
              altText
            }
          }
        }
      }
    }
  }
`

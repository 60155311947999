import React from "react"
import PropTypes from "prop-types"

import { Link } from "components/anti/link/link"

// Button
export const Button = ({
  id,
  loader,
  variant,
  link,
  target,
  type,
  size,
  className,
  onClick,
  children,
  download,
  disabled,
}) => {
  const btnSize = () => {
    if (size === "sm") {
      return "btn-sm"
    } else if (size === "lg") {
      return "btn-lg"
    } else {
      return ""
    }
  }

  if (link) {
    return (
      <Link
        to={link}
        target={target}
        className={`btn btn-${variant} ${btnSize()} ${className}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
        download={download}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </Link>
    )
  } else if (type) {
    return (
      <button
        type={type}
        className={`btn btn-${variant} ${btnSize()} ${className}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </button>
    )
  } else {
    return (
      <button
        className={`btn btn-${variant} ${btnSize()} ${className}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </button>
    )
  }
}

Button.propTypes = {
  variant: PropTypes.string.isRequired, // Set variant (based on Bootstrap classes)
  link: PropTypes.string, // Set button link / href
  target: PropTypes.string,
  type: PropTypes.string, // Set <input> button type attribute
  size: PropTypes.oneOf(["", "sm", "lg", null]), // Set button size
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.any,
  loader: PropTypes.bool,
  children: PropTypes.any,
}

Button.defaultProps = {
  size: "",
  target: null,
  loader: null,
  className: "",
}

// Button Group
export const ButtonGroup = ({
  size,
  btnMargin,
  stackedSmDown,
  className,
  id,
  children,
}) => {
  const btnGroupSize = () => {
    if (size === "sm") {
      return "btn-group-sm"
    } else if (size === "lg") {
      return "btn-group-lg"
    } else {
      return ""
    }
  }
  return (
    <div
      className={`btn-group ${btnGroupSize()} ${btnMargin} ${stackedSmDown &&
        "d-sm-down-stacked"} ${className}`}
      id={id}
    >
      {children}
    </div>
  )
}

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(["", "sm", "lg", null]), // Set button size
  btnMargin: PropTypes.string, // Set button margin (e.g. .mx-1, .mx-2, .mx-3, .mx-10px, .mx-20px, etc.)
  stackedSmDown: PropTypes.bool, // Set button to be stacked on sm down (retaining margin set by .mx-*)
  className: PropTypes.string, // Set optional classes
  children: PropTypes.any,
}

ButtonGroup.defaultProps = {
  size: "",
  btnMargin: "mx-2",
  stackedSmDown: false,
  className: "",
  download: false,
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"
import { Card } from "../../../anti/card/card"

const DevelopmentSubmenu = ({
  navExpand,
  variant,
  closeDevelopmentSubmenu,
  subData,
  lang,
}) => {
  const data = useStaticQuery(GET_DEVELOPMENT_NAVBAR_MOBILE)
  const navbarData = data.wordPress.projectCategories.nodes

  return (
    <>
      <div
        className={`mobile-menu hide d-${navExpand}-none ${variant}`}
        id="developmentMobile"
      >
        <div className="mobile-menu-content pb-0">
          <ul className="navbar-nav mb-auto">
            <li className="nav-item" onClick={closeDevelopmentSubmenu}>
              <div className="container">
                <div
                  className="nav-link back-link"
                  // activeClassName="active"
                >
                  <i className="fal fa-chevron-left mr-2"></i>{" "}
                  {lang === "en" ? "Back" : "Kembali"}
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="container">
                <div
                  className="nav-link"
                  // activeClassName="active"
                >
                  {subData.label}
                </div>
              </div>
            </li>
            <ul className="subnavbar-nav mb-auto">
              <div className="container">
                {subData.data?.map((item, i) => {
                  return (
                    <li className="nav-item">
                      <Link
                        key={i}
                        to={item.url}
                        className="nav-link"
                        // activeClassName="active"
                      >
                        {item.label}
                      </Link>
                    </li>
                  )
                })}
              </div>
            </ul>
            <li className="nav-item">
              <div className="container">
                <Button
                  variant="link"
                  className="text-primary pt-0"
                  link={`${lang === "en" ? "" : "/id"}/development`}
                >
                  {lang === "en" ? "See all Projects" : "Lihat semua Proyek"}
                </Button>
              </div>
            </li>
          </ul>
          <div className="row row-0 developments-list w-100">
            {navbarData.map((dataDev, i) => {
              const development =
                lang === "en" ? dataDev : dataDev.translation || dataDev
              return (
                <Card
                  imgRatio="r-16-9"
                  variant="overlay"
                  titleClassName="h5"
                  className="w-100"
                  title={dataDev.name}
                  img={development.projectCategories.cover.image.sourceUrl}
                  link={`${lang === "en" ? "" : "/id"}/development/${
                    dataDev.slug
                  }`}
                  key={i}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default DevelopmentSubmenu

const GET_DEVELOPMENT_NAVBAR_MOBILE = graphql`
  query DevelopmentNavbarMobile {
    wordPress {
      projectCategories(
        where: { language: EN, orderby: DESCRIPTION }
        first: 100
      ) {
        nodes {
          name
          slug
          projectCategories {
            cover {
              title
              image {
                sourceUrl
                altText
              }
            }
          }
          translation(language: ID) {
            name
            slug
            projectCategories {
              cover {
                title
                image {
                  sourceUrl
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`

import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { useStaticQuery, graphql, Link } from "gatsby"

import { revealFromTop, revealCloseY } from "./animations"
import { Card } from "../../../anti/card/card"
import { Button } from "../../../anti/buttons/buttons"
// import { CardSlider } from "../ak-card-slider/card-slider"

import building from "assets/img/development/building.svg"

const DesktopSubmenu = ({ state, setState, navExpand, lang, subData }) => {
  const data = useStaticQuery(GET_ABOUT_NAVBAR)

  const navbarData =
    lang === "en"
      ? data?.wordPress?.page?.aboutPage?.navbarAbout
      : data?.wordPress?.page?.translation?.aboutPage?.navbarAbout ||
        data?.wordPress?.page?.aboutPage?.navbarAbout

  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)

  // Reveal & close animation
  useEffect(() => {
    if (state.isOpen === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if (state.isOpen === true) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, {
        duration: 0,
        opacity: 1,
        height: "24rem",
      })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [state])

  useEffect(() => {
    menuBackground.addEventListener("click", () => setState({ isOpen: false }))

    return () =>
      menuBackground &&
      menuBackground.removeEventListener("click", () =>
        setState({ isOpen: false })
      )
  }, [])

  return (
    <>
      <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
        <div
          className="navbar-submenu-bg"
          ref={el => (menuBackground = el)}
          onClick={setState}
        />
        <div className="navbar-submenu-content" ref={el => (menuContent = el)}>
          <div className="navbar-submenu-collapse pt-md-3">
            <div className="navbar-submenu-collapse-header">
              <button className="btn btn-link" onClick={setState}>
                {lang === "id" ? "Kembali" : "Back"}
              </button>
            </div>
            <div className="navbar-submenu-collapse-content">
              <div className={`row w-100`}>
                <div className="col-md-3">
                  <h2 ref={el => (line1 = el)}>{subData.label}</h2>
                  {/* <p ref={el => (line2 = el)}>
                      {lang === "en"
                        ? "Discover BSD City current & future developments"
                        : "Temukan pengembangan BSD City saat ini dan masa datang. "}
                    </p> */}
                  <ul className="navbar-nav">
                    {subData.data?.map((sub, i) => {
                      const num = i + 3
                      let line =
                        num === 3
                          ? line3
                          : num === 4
                          ? line4
                          : num === 5
                          ? line5
                          : num === 6
                          ? line8
                          : ""
                      if (sub.url.includes("/contact")) {
                        return (
                          <li className="nav-item">
                            <div ref={el => (line = el)}>
                              {/* prettier-ignore */}
                              <Button variant='primary' className='btn-block' link={sub.url}>
                                {sub.label}
                              </Button>
                            </div>
                          </li>
                        )
                      }
                      return (
                        <li key={i} className="nav-item">
                          <div ref={el => (line = el)}>
                            {/* prettier-ignore */}
                            <Link className="nav-link" 
                              // activeClassName="active"
                            to={sub.url}>
                              {sub.label}
                            </Link>
                          </div>
                        </li>
                      )
                    })}
                    <li className="nav-item my-2">
                      <div ref={line8}>
                        {/* prettier-ignore */}
                        <Button variant='primary' className='btn-block mt-3 text-uppercase' link={`${lang === "en" ? "" : "/id"}/services#contact-us`}>
                          {lang === "en" ? "Contact Us" : "Kontak kami"}
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="offset-md-1 col-md-8">
                  <div className="row">
                    {navbarData.map((project, i) => {
                      return (
                        <div className="col-3 mr-4" key={i}>
                          <Card
                            variant="overlay"
                            title={project.title}
                            titleClassName="h5 font-weight-bold mb-2"
                            img={project.image.sourceUrl}
                            imgRatio="r-2-3"
                            className="card-about-submenu"
                            link={project.url}
                          >
                            <div className="d-flex">
                              <img
                                src={building}
                                className="img-fluid"
                                alt="icon"
                              />
                              <small className="text-white text-truncate">
                                {project.label}
                              </small>
                            </div>
                          </Card>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// const CardList = ({ data }) => {
//   return (
//     <div className="d-none d-lg-block">
//       <div className="row">
//         {data.map((block, i) => {
//           if (i === 0) {
//             return (
//               <div key={`${block.title}-${i}`} className="col-md-4">
//                 <Card
//                   variant="overlay"
//                   title={block.title}
//                   titleClassName="h5"
//                   label={
//                     (block.projectTags.nodes.find(x => x.name !== "Featured") &&
//                       block.projectTags.nodes.find(x => x.name !== "Featured")
//                         .name) ||
//                     ""
//                   }
//                   text={block.propertyTypes.nodes[0].name}
//                   img={block.blocks[0].coverMain.img.sourceUrl}
//                   imgHeight="h-ratio-3-2"
//                   className="card-property submenu-property"
//                   link={`/development/${block.propertyTypes.nodes[0].slug}/${block.slug}`}
//                 />
//               </div>
//             )
//           } else if (i > 0 && i <= 2) {
//             return (
//               <div key={`${block.title}-${i}`} className="col-md-4">
//                 <Card
//                   variant="overlay"
//                   title={block.title}
//                   titleClassName="h5"
//                   label={
//                     (block.projectTags.nodes.find(x => x.name !== "Featured") &&
//                       block.projectTags.nodes.find(x => x.name !== "Featured")
//                         .name) ||
//                     ""
//                   }
//                   text={block.propertyTypes.nodes[0].name}
//                   img={
//                     block.blocks[0].coverMain.img &&
//                     block.blocks[0].coverMain.img.sourceUrl
//                   }
//                   imgHeight="h-ratio-1-1"
//                   className="card-property submenu-property"
//                   link={`/development/${block.propertyTypes.nodes[0].slug}/${block.slug}`}
//                 />
//               </div>
//             )
//           }
//         })}
//       </div>
//     </div>
//   )
// }

export default DesktopSubmenu

const GET_ABOUT_NAVBAR = graphql`
  query Navbar_About {
    wordPress {
      page(id: "about", idType: URI) {
        aboutPage {
          navbarAbout {
            url
            title
            label
            image {
              sourceUrl
              altText
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            navbarAbout {
              url
              title
              label
              image {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  }
`

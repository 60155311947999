import React from "react"
import PropTypes from "prop-types"

import { Img } from "components/anti/utils/utils"

import img from "assets/img/bg_default.jpg"

export const Image = ({
  className,
  id,
  ratio,
  src,
  imgClassName,
  alt,
  onClick,
  isAmp,
}) => {
  return (
    <>
      {ratio ? (
        <div
          className={`image ratio ${ratio} ${className}`}
          id={id}
          onClick={onClick}
        >
          <div className="outer-content">
            <div className="inner-content">
              {isAmp ? (
                <amp-img
                  src={src}
                  className={`${imgClassName}`}
                  alt={alt}
                ></amp-img>
              ) : (
                <img src={src} className={`${imgClassName}`} alt={alt} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {isAmp ? (
            <amp-img
              src={src}
              className={`${imgClassName || className}`}
              alt={alt}
            ></amp-img>
          ) : (
            <img
              src={src}
              className={`${imgClassName || className}`}
              alt={alt}
            />
          )}
        </>
      )}
    </>
  )
}

Image.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any,
  ratio: PropTypes.string, // e.g. .r-16-9, .r-sm-3-2, .r-md-4-3, .r-lg-5-4, .r-xl-1-1
  src: PropTypes.string,
  alt: PropTypes.string,
  imgClassName: PropTypes.string,
  onClick: PropTypes.any,
}

Image.defaultProps = {
  className: "",
  imgClassName: "",
  src: img,
  alt: "Image",
  isAmp: false,
}

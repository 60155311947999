import React from "react"
import { Link } from "components/anti/link/link"

const FooterContact = ({ title, text, links, socialMediaLinks }) => {
  return (
    <div className="footer-contact">
      <h5 className="pb-4">{title}</h5>
      <p>Sinar Mas Land Plaza</p>
      <p className="text-muted w-md-350px pb-3">{text}</p>
      {/* {links.map((link, i) => {
        return (
          <div className="d-block">
            <Link key={i} to={link.link}>
              {link.title}
            </Link>
          </div>
        )
      })} */}
    </div>
  )
}

export default FooterContact

import React from "react"

import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"

const ServicesSubmenu = ({
  navExpand,
  variant,
  closeServicesSubmenu,
  subData,
  lang,
}) => {
  return (
    <>
      <div
        className={`mobile-menu hide d-${navExpand}-none ${variant}`}
        id="servicesMobile"
      >
        <div className="mobile-menu-content">
          <div className="container">
            <ul className="navbar-nav mb-auto">
              <li className="nav-item" onClick={closeServicesSubmenu}>
                <div
                  className="nav-link back-link"
                  // activeClassName="active"
                >
                  <i className="fal fa-chevron-left mr-2"></i>{" "}
                  {lang === "en" ? "Back" : "Kembali"}
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  // activeClassName="active"
                >
                  {subData.label}
                </div>
                <ul className="subnavbar-nav mb-auto">
                  {subData.data?.map((sub, i) => {
                    return (
                      <li key={i} className="nav-item">
                        <Link
                          to={sub.url}
                          className="nav-link"
                          // activeClassName="active"
                        >
                          {sub.label}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </ul>
            <Button
              variant="primary"
              className="btn-block nav-mobile-btn mt-3"
              link={`${lang === "id" ? "/id" : ""}/services#contact-us`}
            >
              {lang === "en" ? "CONTACT US" : "HUBUNGI KAMI"}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesSubmenu

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "components/anti/link/link"

import DesktopMenu from "./components/desktop-menu"
import DesktopMenuAlt from "./components/desktop-menu-alt"
import MobileMenu from "./components/mobile-menu"
import MobileMenuAlt from "./components/mobile-menu-alt"
import SearchMenu from "./components/search-menu"

import logoDark from "assets/img/logo-duta-pertiwi.svg"
// import logoDarkPng from "assets/img/logo-duta-pertiwi.png"
import logoLight from "assets/img/logo-duta-pertiwi-white.svg"
// import { gql } from "@apollo/client"
import { navigate } from "gatsby"

const Navbar = ({ theme, pathname, lang, alt, isAmp }) => {
  // const data = useStaticQuery(GET_NAVBAR_DETAILS)
  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "xl"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  const navTheme = theme ? `navbar-${theme}` : "navbar-light"
  // const navTheme = theme === "bg-light" ? "navbar-light" : "navbar-dark"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
  })
  const [aboutMobileMenu, setAboutMobileMenu] = useState({ clicked: false })
  const [investorsMobileMenu, setinvestorsMobileMenu] = useState({
    clicked: false,
  })
  const [servicesMobileMenu, setServicesMobileMenu] = useState({
    clicked: false,
  })
  const [developmentMobileMenu, setDevelopmentMobileMenu] = useState({
    clicked: false,
  })

  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)
  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleMenuMobile = () => {
    disableMenu()
    setMobileMenu({ clicked: !mobileMenu.clicked })
    setAboutMobileMenu({ clicked: false })
    setinvestorsMobileMenu({ clicked: false })
    setServicesMobileMenu({ clicked: false })
    setDevelopmentMobileMenu({ clicked: false })
  }

  useEffect(() => {
    const navToggler = document.querySelector(".nav-toggler")
    const navMobileMenu = document.getElementById("mobileNav")
    const menuBg = document.querySelector(".menu-bg")
    const html = document.querySelector("html")
    const body = document.querySelector("body")
    // prettier-ignore
    if (mobileMenu.clicked === false) {
      // Toggle inactive
      navToggler.classList.add("inactive")
      navMobileMenu.classList.replace("show", "hide")
      menuBg.classList.replace("show", "hide")
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
    } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
      // body.classList.add("no-scroll")
      // html.classList.add("no-scroll")
    }
  }, [mobileMenu])

  useEffect(() => {
    if (!alt && !isAmp) {
      const aboutMobileNav = document.getElementById("aboutMobile")
      if (aboutMobileMenu.clicked) {
        aboutMobileNav.classList.replace("hide", "show")
      } else if (!aboutMobileMenu.clicked) {
        aboutMobileNav.classList.replace("show", "hide")
      }
    }
  }, [aboutMobileMenu, isAmp])

  useEffect(() => {
    if (!alt && !isAmp) {
      const investorsMobileNav = document.getElementById("investorsMobile")
      if (investorsMobileMenu.clicked) {
        investorsMobileNav.classList.replace("hide", "show")
      } else if (!investorsMobileMenu.clicked) {
        investorsMobileNav.classList.replace("show", "hide")
      }
    }
  }, [investorsMobileMenu, isAmp])

  useEffect(() => {
    if (!alt && !isAmp) {
      const servicesMobileNav = document.getElementById("servicesMobile")
      if (servicesMobileMenu.clicked) {
        servicesMobileNav.classList.replace("hide", "show")
      } else if (!servicesMobileMenu.clicked) {
        servicesMobileNav.classList.replace("show", "hide")
      }
    }
  }, [servicesMobileMenu, isAmp])

  useEffect(() => {
    if (!alt && !isAmp) {
      const developmentMobileNav = document.getElementById("developmentMobile")
      if (developmentMobileMenu.clicked) {
        developmentMobileNav.classList.replace("hide", "show")
      } else if (!developmentMobileMenu.clicked) {
        developmentMobileNav.classList.replace("show", "hide")
      }
    }
  }, [developmentMobileMenu, isAmp])

  // SEARCH
  const [searchInput, setSearchInput] = useState("")
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    clicked: false,
  })
  const goSearch = e => {
    if (e.key === "Enter" && searchInput.length > 0) {
      navigate(`${lang === "en" ? "" : "/id"}/search-result?q=${searchInput}`)
    }
    setSearchInput("")
  }

  const goSearchMobile = e => {
    navigate(`${lang === "en" ? "" : "/id"}/search-result?q=${searchInput}`)
    setSearchInput("")
  }
  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)
  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  // change input search
  const handleChangeSearch = e => {
    setSearchInput(e.target.value)
  }
  const handleSearch = () => {
    disableSearch()
    setSearchInput("")
    if (search.clicked === true) {
      setSearch({ clicked: !search.clicked })
    } else if (search.clicked === false) {
      setSearch({ clicked: !search.clicked })
    }
  }
  useEffect(() => {
    const searchMenu = document.querySelector(".search-menu")
    const navbarMain = document.querySelector(".navbar-main")
    // prettier-ignore
    if (search.clicked === false) {
      // Toggle inactive
      searchMenu.classList.remove("show")
      searchMenu.classList.add("hide")
      navbarMain.classList.remove("search-show")
      navbarMain.classList.add("search-hide")
    } else if (search.clicked === true) {
      // Toggle active
      searchMenu.classList.add("show")
      navbarMain.classList.add("search-show")
    }
  }, [search])

  //on scroll
  const [navScroll, setNavScroll] = useState(false)
  const [navOpen, setNavOpen] = useState(false)

  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const containsClass = navbar.classList.contains("light")

    window.onscroll = function() {
      if (!containsClass) {
        if (window.pageYOffset > 5) {
          setNavScroll(true)
        } else {
          setNavScroll(false)
        }
      }
    }
  }, [])

  // // Language state handler
  const [path, setPath] = useState(pathname)

  useEffect(() => {
    const trimPath = path.replace("/id", "").replace(/\/$/, "")
    setPath(trimPath)
  }, [path])

  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
      <nav
        className={`navbar ${navTheme} navbar-expand-${navExpand} ${navPosition}  dark 
        ${
          navScroll || search.clicked || navOpen ? "is-scrolled" : ""
        } ${!isAmp && "transparent"}`}
      >
        <div className="navbar-top d-md-block d-none">
          <div className="container">
            <ul className="col-left">
              {/* <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "en" && "active"}`} to={`${path ? path : "/"}`}>
                  EN
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "id" && "active"}`} to={`/id${path}`}>
                  ID
                </Link>
              </li> */}
            </ul>
            <ul className="col-right">
              <li className="nav-item">
                <Link
                  className="nav-link text-uppercase font-weight-bold"
                  to="/login"
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="navbar-main">
          <div className="container">
            <ul className="col-left">
              <li className="nav-item-brand">
                <Link className="navbar-brand" to={lang === "en" ? "/" : "/id"}>
                  {isAmp ? (
                    <>
                      <amp-img
                        src={logoLight}
                        class="img-fluid logo-light"
                        alt="Logo"
                        width="140"
                        height="30"
                      />
                      <amp-img
                        src={logoDark}
                        class="img-fluid logo-dark"
                        alt="Logo"
                        width="140"
                        height="30"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={logoLight}
                        className="logo-light img-fluid"
                        alt="Duta Pertiwi Logo"
                      />
                      <img
                        src={logoDark}
                        className="logo-dark img-fluid"
                        alt="Duta Pertiwi Logo"
                      />
                    </>
                  )}
                </Link>
              </li>
            </ul>

            {/* // amp navbar */}
            {isAmp && (
              <div>
                <amp-sidebar id="sidebar-right" layout="nodisplay" side="right">
                  <amp-img
                    class="amp-close-image"
                    src="https://png.icons8.com/ios/50/000000/multiply-filled.png"
                    width="20"
                    height="20"
                    alt="close sidebar"
                    on="tap:sidebar-left.close"
                    role="button"
                    tabindex="0"
                  ></amp-img>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/investor">Investor Area</a>
                    </li>
                    <li>
                      <a href="/development">Development</a>
                    </li>
                    <li>
                      <a href="/facilities">Facilities</a>
                    </li>
                    <li>
                      <a href="/news-events">News & Events</a>
                    </li>
                    <li>
                      <a href="/access">Access</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                  </ul>
                </amp-sidebar>

                <div id="target-element-right"></div>
              </div>
            )}

            {/* Mobile */}
            <ul className={`col-right ${!isAmp && `d-${navExpand}-none`}`}>
              {!pathname?.includes("/search-result") && !alt && !isAmp && (
                <li className="nav-item">
                  <button
                    className="nav-icon search-toggler"
                    disabled={disabledSearch}
                    onClick={handleSearch}
                  >
                    <i className="air ai-search" />
                  </button>
                </li>
              )}
              <li className="nav-item">
                <button
                  className="nav-icon nav-toggler inactive absolute ampstart-btn caps m2"
                  disabled={disabledMenu}
                  onClick={!isAmp ? handleMenuMobile : null}
                  on={isAmp && "tap:sidebar-right.toggle"}
                >
                  <span className="icon-bar top-bar" />
                  <span className="icon-bar middle-bar" />{" "}
                  <span className="icon-bar bottom-bar" />
                </button>
              </li>
            </ul>

            {/* Desktop */}
            {!alt ? (
              <>
                {!isAmp && (
                  <DesktopMenu
                    disabledSearch={disabledSearch}
                    handleSearch={handleSearch}
                    navExpand={navExpand}
                    setNavOpen={setNavOpen}
                    path={path}
                    // dataNavbar={data}
                    lang={lang}
                  />
                )}
              </>
            ) : (
              <DesktopMenuAlt navExpand={navExpand} />
            )}
          </div>
          <SearchMenu
            goSearch={goSearch}
            handleChangeSearch={handleChangeSearch}
            handleSearch={handleSearch}
            variant="expand"
          />
        </div>
        {!isAmp && (
          <>
            {!alt ? (
              <MobileMenu
                lang={lang}
                // mobileMenu={mobileMenu}
                navExpand={navExpand}
                setAboutMenu={() => setAboutMobileMenu({ clicked: true })}
                closeAboutSubmenu={() => setAboutMobileMenu({ clicked: false })}
                setInvestorSubmenu={() =>
                  setinvestorsMobileMenu({ clicked: true })
                }
                cloaseInvestorSubmenu={() =>
                  setinvestorsMobileMenu({ clicked: false })
                }
                setServicesSubmenu={() =>
                  setServicesMobileMenu({ clicked: true })
                }
                closeServicesSubmenu={() =>
                  setServicesMobileMenu({ clicked: false })
                }
                setDevelopmentSubmenu={() =>
                  setDevelopmentMobileMenu({ clicked: true })
                }
                closeDevelopmentSubmenu={() =>
                  setDevelopmentMobileMenu({ clicked: false })
                }
                variant="slide-right"
                goSearch={goSearch}
                goSearchMobile={goSearchMobile}
                handleChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                path={path}
              />
            ) : (
              <MobileMenuAlt variant="slide-right" navExpand={navExpand} />
            )}
          </>
        )}
        <div className="menu-bg" />
      </nav>
    </>
  )
}

export default Navbar

const GET_NAVBAR_DETAILS = graphql`
  query {
    wordPress {
      menus {
        nodes {
          name
          slug
          menuItems {
            nodes {
              url
              label
              childItems {
                nodes {
                  label
                  url
                  childItems {
                    nodes {
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

{
  /* <ul>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/">Home</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/about">About</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/investor">Investor Area</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/development">Development</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/facilities">Facilities</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/news-events">News & Events</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/access">Access</a>
  </amp-fit-text>
</li>
<li>
  <amp-fit-text width="220" height="20" layout="responsive" max-font-size="24">
    <a href="/services">Services</a>
  </amp-fit-text>
</li>
</ul> */
}

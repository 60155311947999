import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { useStaticQuery, graphql } from "gatsby"

import { Button } from "components/anti/buttons/buttons"
import { revealFromTop, revealCloseY } from "./animations"
import { Card } from "../../../anti/card/card"
import { Slider } from "../../../anti/slider/slider"


const DesktopDevelopmentSubmenu = ({ state, setState, navExpand, lang, subData }) => {
  const data = useStaticQuery(GET_DEVELOPMENT_NAVBAR)
  const navbarData = data.wordPress.projectCategories.nodes

  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)

  const projects = []

  // Reveal & close animation
  useEffect(() => {
    if (state.isOpen === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if (state.isOpen === true) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, {
        duration: 0,
        opacity: 1,
        height: "21.5rem",
      })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [state])

  useEffect(() => {
    menuBackground.addEventListener("click", () => setState({ isOpen: false }))

    return () =>
      menuBackground && menuBackground.removeEventListener("click", () =>
        setState({ isOpen: false })
      )
  }, [])


  return (
    <>
      <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
        <div
          className="navbar-submenu-bg"
          ref={el => (menuBackground = el)}
          onClick={setState}
        />
        <div className="navbar-submenu-content" ref={el => (menuContent = el)}>
          <div className="navbar-submenu-collapse pt-md-3">
            {/* <div className="navbar-submenu-collapse-header">
              <button className="btn btn-link" onClick={setState}>
                {lang === "en" ? "Back" : "Kembali"}
              </button>
            </div> */}
            <div className="navbar-submenu-collapse-content">
              <div className={`row w-100`}>
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <h2 className="ml-2 mb-4" ref={el => (line1 = el)}>
                    {subData.label}
                  </h2>
                  <Button variant="link" className="text-primary" link={`${lang === "en" ? "" : "/id"}/development`} >{lang === "en" ? "See all Projects" : "Lihat semua Proyek"}</Button>
                </div>
                <Slider
                  showInitial={3.5}
                  showXxlDown={3.5}
                  showxlDown={3.5}
                  showLgDown={3.5}
                  showMdDown={3}
                  showSmDown={3}
                  arrowsInitial={true}
                  arrowsXxlDown={true}
                  arrowsXlDown={true}
                  arrowsLgDown={true}
                  arrowsMdDown={true}
                  arrowsSmDown={false}
                  className="w-100 navbar-submenu-collapse-slider"
                >
                  {navbarData.map((dataDev, i) => {
                    const development = lang === "en" ? dataDev : dataDev.translation || dataDev
                    return (
                      <Card
                        imgRatio="r-2-1"
                        variant="overlay"
                        titleClassName="h5"
                        title={dataDev.name}
                        img={development.projectCategories.cover.image.sourceUrl}
                        link={`${lang === "en" ? "" : "/id"}/development/${dataDev.slug}`}
                        key={i}
                      />
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopDevelopmentSubmenu


const GET_DEVELOPMENT_NAVBAR = graphql`
query DevelopmentNavbar {
  wordPress{
    projectCategories(where: {language: EN, orderby: DESCRIPTION}, first: 100) {
      nodes {
        name
        slug
        projectCategories {
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
        }
        translation(language: ID) {
          name
          slug
          projectCategories {
            cover {
              title
              image {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  }
}
`
import React from "react"

import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"

const InvestorsSubmenu = ({
  navExpand,
  variant,
  cloaseInvestorSubmenu,
  subData,
  lang,
}) => {
  return (
    <>
      <div
        className={`mobile-menu hide d-${navExpand}-none ${variant}`}
        id="investorsMobile"
      >
        <div className="mobile-menu-content">
          <div className="container">
            <ul className="navbar-nav mb-auto">
              <li className="nav-item" onClick={cloaseInvestorSubmenu}>
                <div
                  className="nav-link back-link"
                  // activeClassName="active"
                >
                  <i className="fal fa-chevron-left mr-2"></i>{" "}
                  {lang === "en" ? "Back" : "Kembali"}
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  // activeClassName="active"
                >
                  {subData.label}
                </div>
                <ul className="subnavbar-nav mb-auto">
                  {subData.data?.map((item, i) => {
                    return (
                      <li className="nav-item">
                        <Link
                          key={i}
                          to={item.url}
                          className="nav-link"
                          // activeClassName="active"
                        >
                          {item.label}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </ul>
            <Button
              variant="primary"
              className="btn-block nav-mobile-btn mt-3"
              link={`${
                lang === "en" ? "" : "/id"
              }/investor/investors-relations`}
            >
              {lang === "en" ? "Investor Relations" : "Hubungan Investor"}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvestorsSubmenu

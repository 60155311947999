import React from "react"
import moment from "moment"

import { Link } from "components/anti/link/link"

import FooterNewsletter from "./components/newsletter"
import FooterContact from "./components/contact"
import FooterMenu from "./components/menu"
import FooterSocial from "./components/social"

// import logoDark from "assets/img/logo_main-dark.png"
import imgRedRibbon from "assets/img/img_red-ribbon.png"
import { graphql, useStaticQuery } from "gatsby"

const Footer = ({ lang, isAmp }) => {
  const data = useStaticQuery(GET_FOOTER)
  const menuLinks =
    lang === "en"
      ? data.wordPress.menus.nodes[0].menuItems.nodes
      : data.wordPress.translation.nodes[0].menuItems.nodes ||
        data.wordPress.menus.nodes[0].menuItems.nodes

  const socialMedia =
    lang === "en"
      ? data.wordPress.socialMedia.nodes[0].menuItems.nodes
      : data.wordPress.socialMediaTranslation.nodes[0].menuItems.nodes ||
        data.wordPress.socialMedia.nodes[0].menuItems.nodes

  const contactLinks = [
    { title: "+62 811 170 165", link: "phone:+62811170165" },
    { title: "info@antikode.com", link: "mailto:info@antikode.com" },
  ]

  const year = moment().year()
  return (
    <footer>
      <div className="red-ribbon">
        <div className="ribbon red" />
        <div className="ribbon black" />
        {isAmp ? (
          <amp-img
            src={imgRedRibbon}
            class="img-fluid"
            alt="Red Ribbon"
            width="267"
            height="90"
          />
        ) : (
          <img src={imgRedRibbon} className="img-fluid" alt="Red Ribbon" />
        )}
      </div>
      <section className="footer-main pt-5 pb-main">
        <div className="container">
          <div className="row row-4">
            {/* <div className="col-lg-4 col-footer">
              <FooterAbout
                logo={logoDark}
                text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate voluptas voluptatum ipsam, natus labore explicabo. Minus praesentium dicta fugit itaque. "
              />
            </div> */}
            <div className="col-lg-4 col-footer">
              <FooterMenu
                title={lang === "en" ? "Quick Links" : "Tautan Langsung"}
                links={menuLinks}
              />
            </div>
            <div className="col-lg-4 col-footer">
              <FooterContact
                title={lang === "en" ? "REACH US AT" : "HUBUNGI KAMI DI"}
                text="Jl. Grand Boulevard, BSD City, Tangerang, Banten Indonesia 15345"
                links={contactLinks}
                socialMediaLinks={socialMedia}
              />
            </div>
            <div className="col-lg-4 col-footer">
              <h5 className="text-uppercase pb-4">Follow us On</h5>
              <FooterSocial socialMediaLinks={socialMedia} isAmp={isAmp} />
              {/* <FooterNewsletter lang={lang} /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <div className="container">
          <div className="row w-100">
            <div className="col-md-6 col-left footer-brand">
              {`© ${year} Duta Pertiwi.`}
            </div>
            <div className="col-md-6 col-right">
              <Link to={`${lang === "en" ? "" : "/id"}/privacy-policy`}>
                {lang === "en" ? "Privacy Policy" : "Kebijakan Privasi"}
              </Link>
              <span className="divider">|</span>
              <Link to={`${lang === "en" ? "" : "/id"}/terms-conditions`}>
                {lang === "en" ? "Terms & Conditions" : "Syarat & Ketentuan"}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer

const GET_FOOTER = graphql`
  query GET_FOOTER {
    wordPress {
      menus(where: { location: FOOTER_MENU }) {
        nodes {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
      translation: menus(where: { location: FOOTER_MENU___ID }) {
        nodes {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
      socialMedia: menus(where: { location: SOCIAL_MENU }) {
        nodes {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
      socialMediaTranslation: menus(where: { location: SOCIAL_MENU___ID }) {
        nodes {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
  }
`

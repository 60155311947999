import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const FooterSocial = ({ className, socialMediaLinks, isAmp }) => {
  const [social, setSocial] = useState([])

  useEffect(() => {
    let links = socialMediaLinks?.map(el => {
      if (el.url.includes("facebook.com")) {
        return {
          iconName: "ai-facebook-square",
          url: el.url,
          className: "gtm-fb",
        }
      } else if (el.url.includes("instagram.com")) {
        return { iconName: "ai-instagram", url: el.url, className: "gtm-ig" }
      } else if (el.url.includes("twitter.com")) {
        return { iconName: "ai-twitter", url: el.url, className: "gtm-twitter" }
      } else if (el.url.includes("youtube.com")) {
        return { iconName: "ai-youtube", url: el.url, className: "gtm-youtube" }
      }
    })
    setSocial(links)
  }, [])

  return (
    <ul className={`social ${className}`}>
      {!isAmp &&
        social.map((item, i) => {
          return (
            <li key={i} className="social-item">
              <a
                href={item.url}
                target="_blank"
                className={`social-link ${item.className}`}
              >
                <i className={`aib ${item.iconName}`} />
              </a>
            </li>
          )
        })}
      {isAmp && (
        <>
          <li className="social-item social-amp-item">
            <a
              href={"https://www.facebook.com/sinarmasland/"}
              target="_blank"
              className={`social-link gtm-fb`}
            >
              {/* <i className={`aib ai-facebook-square`} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path>
              </svg>
            </a>
          </li>
          <li className="social-item social-amp-item">
            <a
              href={"https://twitter.com/sinarmas_land"}
              target="_blank"
              className={`social-link gtm-twitter`}
            >
              {/* <i className={`aib ai-twitter`} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-************************** *************************-8.9 13.1-20.1 24.7-32.9 34z"></path>
              </svg>
            </a>
          </li>
          <li className="social-item social-amp-item">
            <a
              href={"https://www.youtube.com/user/sinarmasland"}
              target="_blank"
              className={`social-link gtm-youtube`}
            >
              {/* <i className={`aib ai-youtube`} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
              </svg>
            </a>
          </li>
          <li className="social-item social-amp-item">
            <a
              href={"https://www.instagram.com/sinarmas_land/"}
              target="_blank"
              className={`social-link gtm-ig`}
            >
              {/* <i className={`aib ai-instagram`} /> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224.1 141c-***************************.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.*******************************-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.******************************* 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
              </svg> */}
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="48px"
                height="48px"
              >
                <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
              </svg>
            </a>
          </li>
        </>
      )}
    </ul>
  )
}

FooterSocial.propTypes = {
  className: PropTypes.string, // Set optional classes
}

FooterSocial.defaultProps = {
  className: "",
}

export default FooterSocial

import React, { useEffect, useState } from "react"

import { Link } from "components/anti/link/link"

//assets
import fill1 from "assets/img/fill1.svg"
import fill2 from "assets/img/fill2.svg"
import fill3 from "assets/img/fill3.svg"

const MobileMenuAlt = ({ navExpand, variant }) => {
  const navLink = [
    {
      title: "Login",
      link: "/login",
    },
    {
      title: "Register",
      link: "/register",
    },
    {
      title: "Subscribe",
      link: "/subscribe",
    },
  ]

  return (
    <>
      <div
        id="mobileNav"
        className={`mobile-menu hide d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content position-relative">
          <img src={fill1} alt="gfx" className="gfx-1 img-fluid" />
          <img src={fill2} alt="gfx" className="gfx-2 img-fluid" />
          <img src={fill3} alt="gfx" className="gfx-3 img-fluid" />
          <div className="container position-relative">
            {/* <ul className="mobile-lang">
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "en" && "active"}`} to={`${path ? path : "/"}`}>
                  EN
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "id" && "active"}`} to={`/id${path}`}>
                  ID
                </Link>
              </li>
            </ul> */}
            <ul className="navbar-nav mb-auto">
              {navLink.map((nav, i) => (
                <li className="nav-item" key={i}>
                  <Link
                    className={`nav-link anim-${i + 1}`}
                    // activeClassName="active"
                    to={nav.link}
                  >
                    {nav.title}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <Button variant="primary" className={`btn-block nav-mobile-btn mt-3 anim-${9} text-uppercase`}>
              {lang === "id" ? "Masuk" : "Login"}
            </Button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenuAlt

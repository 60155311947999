import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "components/anti/link/link"
import { Button } from "components/anti/buttons/buttons"

import AboutSubmenu from "./mobile-about-submenu"
import InvestorsSubmenu from "./mobile-investors-submenu"
import ServicesSubmenu from "./mobile-services-submenu"
import DevelopmentSubmenu from "./mobile-development-submenu"

//assets
import fill1 from "assets/img/fill1.svg"
import fill2 from "assets/img/fill2.svg"
import fill3 from "assets/img/fill3.svg"

const MobileMenu = ({
  lang,
  navExpand,
  variant,
  setAboutMenu,
  closeAboutSubmenu,
  setInvestorSubmenu,
  cloaseInvestorSubmenu,
  setServicesSubmenu,
  closeServicesSubmenu,
  setDevelopmentSubmenu,
  closeDevelopmentSubmenu,
  goSearch,
  goSearchMobile,
  handleChangeSearch,
  path,
}) => {
  const data = useStaticQuery(GET_HEADER_MENU_MOBILE)
  const [subData, setSubData] = useState([])

  const menu =
    lang === "id"
      ? data.wordPress.translation.nodes[0].menuItems.nodes
      : data.wordPress.menus.nodes[0].menuItems.nodes

  const toogler = (sectionName, items) => {
    setSubData({ label: sectionName, data: items })
    switch (sectionName) {
      case "About":
      case "Tentang":
        return setAboutMenu()
      case "Investor's Area":
      case "Area Investor":
        return setInvestorSubmenu()
      case "Services":
      case "Layanan":
        return setServicesSubmenu()
      case "Development":
      case "Pengembangan":
        return setDevelopmentSubmenu()
      default:
        return (() => {})()
    }
  }

  // for active dropdown class

  const [aboutActive, setAboutActive] = useState(false)
  const [investorActive, setInvestorActive] = useState(false)
  const [developmentActive, setDevelopmentActive] = useState(false)
  const [servicesActive, setServicesActive] = useState(false)

  const [searchInput, setSearchInput] = useState("")

  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname?.indexOf("about") >= 0) {
      setAboutActive(true)
    } else if (pathname?.indexOf("investor") >= 0) {
      setInvestorActive(true)
    } else if (pathname?.indexOf("development") >= 0) {
      setDevelopmentActive(true)
    } else if (pathname?.indexOf("services") >= 0) {
      setServicesActive(true)
    }
  }, [])

  return (
    <>
      <div
        id="mobileNav"
        className={`mobile-menu hide d-${navExpand}-none ${variant}`}
      >
        <div className="mobile-menu-content position-relative">
          <img src={fill1} alt="gfx" className="gfx-1 img-fluid" />
          <img src={fill2} alt="gfx" className="gfx-2 img-fluid" />
          <img src={fill3} alt="gfx" className="gfx-3 img-fluid" />
          <div className="container position-relative">
            {/* <ul className="mobile-lang">
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "en" && "active"}`} to={`${path ? path : "/"}`}>
                  EN
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link lang-btn ${lang === "id" && "active"}`} to={`/id${path}`}>
                  ID
                </Link>
              </li>
            </ul> */}
            <div className="form-group w-100">
              <div className="form-control-wrapper form-search w-100">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control mobile-search pr-0"
                  onChange={e => {
                    handleChangeSearch(e)
                    setSearchInput(e.target.value)
                  }}
                  onKeyPress={goSearch}
                  value={searchInput}
                />
                {searchInput ? (
                  <div
                    className="form-search-icon mobile"
                    onClick={() => setSearchInput("")}
                  >
                    <i className="ail ai-times" />
                  </div>
                ) : (
                  <div className="form-search-icon mobile">
                    <i className="air ai-search" />
                  </div>
                )}
              </div>
            </div>
            <ul className="navbar-nav mb-auto">
              {menu?.map((item, i) => {
                let section =
                  item.label === "About" || item.label === "Tentang"
                    ? aboutActive
                    : item.label === "Development" ||
                      item.label === "Area Investor"
                    ? developmentActive
                    : item.label === "Investor's Area" ||
                      item.label === "Pengembangan"
                    ? investorActive
                    : item.label === "Services" || item.label === "Layanan"
                    ? servicesActive
                    : false
                if (item.url === "#") {
                  return (
                    <li className="nav-item" key={i}>
                      <div
                        className={`nav-link anim-${i + 1} ${section &&
                          "active"}`}
                        // activeClassName="active"
                        onClick={() =>
                          toogler(item.label, item.childItems.nodes)
                        }
                      >
                        {item.label}
                      </div>
                    </li>
                  )
                }
                return (
                  <li className="nav-item" key={i}>
                    <Link
                      className={`nav-link anim-${i + 1}`}
                      // activeClassName="active"
                      to={item.url}
                    >
                      {item.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
            {/* <Button variant="primary" className={`btn-block nav-mobile-btn mt-3 anim-${9} text-uppercase`}>
              {lang === "id" ? "Masuk" : "Login"}
            </Button> */}
          </div>
        </div>
      </div>

      <AboutSubmenu
        variant="slide-right alt"
        closeAboutSubmenu={closeAboutSubmenu}
        subData={subData}
        lang={lang}
      />
      <InvestorsSubmenu
        variant="slide-right alt"
        cloaseInvestorSubmenu={cloaseInvestorSubmenu}
        subData={subData}
        lang={lang}
      />
      <ServicesSubmenu
        variant="slide-right alt"
        closeServicesSubmenu={closeServicesSubmenu}
        subData={subData}
        lang={lang}
      />
      <DevelopmentSubmenu
        variant="slide-right alt"
        closeDevelopmentSubmenu={closeDevelopmentSubmenu}
        subData={subData}
        lang={lang}
      />
    </>
  )
}

export default MobileMenu

const GET_HEADER_MENU_MOBILE = graphql`
  query GET_HEADER_MENU_MOBILE {
    wordPress {
      menus(where: { location: HEADER_MENU }) {
        nodes {
          menuItems(where: { parentId: 0 }) {
            nodes {
              parentId
              label
              url
              locations
              childItems {
                nodes {
                  parentId
                  label
                  url
                }
              }
            }
          }
        }
      }
      translation: menus(where: { location: HEADER_MENU___ID }) {
        nodes {
          menuItems(where: { parentId: 0 }) {
            nodes {
              parentId
              label
              url
              locations
              childItems {
                nodes {
                  parentId
                  label
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { Link } from "gatsby"

import { revealFromTop, revealCloseY } from "./animations"
import { Button } from "../../../anti/buttons/buttons"

const DesktopServicesSubmenu = ({
  state,
  setState,
  navExpand,
  lang,
  subData,
}) => {
  // MOBILE SUBMENU - Animation
  // Element ref.
  let menuWrapper = useRef(null)
  let menuBackground = useRef(null)
  let menuContent = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let line4 = useRef(null)
  let line5 = useRef(null)
  let line6 = useRef(null)
  let line7 = useRef(null)
  let line8 = useRef(null)

  const projects = []

  // Reveal & close animation
  useEffect(() => {
    if (state.isOpen === false) {
      revealCloseY(menuContent)
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "0" } })
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } })
    } else if (state.isOpen === true) {
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } })
      gsap.to(menuContent, {
        duration: 0,
        opacity: 1,
        height: "20rem",
      })
      gsap.to(menuBackground, { duration: 0.8, css: { opacity: "1" } })
      revealFromTop(menuContent)
    }
  }, [state])

  useEffect(() => {
    menuBackground.addEventListener("click", () => setState({ isOpen: false }))

    return () =>
      menuBackground &&
      menuBackground.removeEventListener("click", () =>
        setState({ isOpen: false })
      )
  }, [])

  return (
    <>
      <div className="navbar-submenu light" ref={el => (menuWrapper = el)}>
        <div
          className="navbar-submenu-bg"
          ref={el => (menuBackground = el)}
          onClick={setState}
        />
        <div className="navbar-submenu-content" ref={el => (menuContent = el)}>
          <div className="navbar-submenu-collapse pt-md-3">
            <div className="navbar-submenu-collapse-header">
              <button className="btn btn-link" onClick={setState}>
                {lang === "id" ? "Kembali" : "Back"}
              </button>
            </div>
            <div className="navbar-submenu-collapse-content">
              <div className={`row w-100`}>
                <div className="col-md-3">
                  <h2 ref={el => (line1 = el)}>{subData.label}</h2>
                  {/* <p ref={el => (line2 = el)}>
                      {lang === "en"
                        ? "Discover BSD City current & future developments"
                        : "Temukan pengembangan BSD City saat ini dan masa datang. "}
                    </p> */}
                  <ul className="navbar-nav">
                    {subData.data?.map((sub, i) => {
                      const num = i + 3
                      let line =
                        num === 3
                          ? line3
                          : num === 4
                          ? line4
                          : num === 5
                          ? line5
                          : num === 6
                          ? line8
                          : ""
                      if (
                        sub.label.toLowerCase().includes("contact us") ||
                        sub.label.toLowerCase().includes("kontak") ||
                        sub.label.toLowerCase().includes("hubungi")
                      ) {
                        return (
                          <li key={i} className="nav-item my-2">
                            <div ref={el => (line = el)}>
                              {/* prettier-ignore */}
                              <Button variant='primary' className='btn-block mt-3 text-uppercase' link={`${lang === "en" ? "" : "/id"}${sub.url}`}>
                                {sub.label}
                              </Button>
                            </div>
                          </li>
                        )
                      } else {
                        return (
                          <li key={i} className="nav-item">
                            <div ref={el => (line = el)}>
                              {/* prettier-ignore */}
                              <Link className="nav-link"
                                // activeClassName="active"
                              to={sub.url}>
                                {sub.label}
                              </Link>
                            </div>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </div>
                <div className="col-md-8"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesktopServicesSubmenu

import React, { useLayoutEffect } from "react"
import { Link } from "components/anti/link/link"
// import { Button } from "components/anti/buttons/buttons"

const DesktopMenuAlt = ({ navExpand }) => {
  const cdcKey = process.env.GATSBY_CDC

  const navLink = [
    {
      title: "Login",
      link: "/login",
    },
    {
      title: "Register",
      link: "/register",
    },
    {
      title: "Subscribe",
      link: "/subscribe",
    },
  ]

  useLayoutEffect(() => {
    // load SAP cdn at header
    ;(function loadCDCCdn() {
      var script = document.createElement("script")
      script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${cdcKey}`
      document.getElementsByTagName("script")[0].parentNode.appendChild(script)
    })()
  }, [])

  return (
    <>
      <div className={`desktop-menu d-none d-${navExpand}-flex`}>
        <ul className="navbar-nav ml-auto">
          {navLink.map((nav, i) => (
            <li className="nav-item" key={i}>
              <Link
                className="nav-link"
                // activeClassName="active"
                to={nav.link}
              >
                {nav.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default DesktopMenuAlt

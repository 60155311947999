import React, { useEffect, useLayoutEffect } from "react"
import { navigate } from "gatsby"

import Navbar from "components/global/navbar/navbar"
import Footer from "components/global/footer/footer"

import { Loader } from "components/anti/loader/loader"

import { getUtmParams } from "../utils/handle-utm"

const Layout = ({ children, theme, path, lang, alt, isAmp }) => {
  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])
  // Transition link
  // for smooth transition effect
  useLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach(item => {
      const navigation = e => {
        const redirect = item.getAttribute("href")
        const noDirect = item.getAttribute("id")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect && !noDirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        } else if (noDirect) {
          navigate(redirect)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach(item => {
        const navigation = e => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  useEffect(() => {
    getUtmParams(window.location.search)
    const splitUndefined = window.location.search
    const pathname = window.location.pathname
    if (splitUndefined === "?undefined=undefined") navigate(pathname)
  }, [])

  useEffect(() => {
    const pathIdentifier = "/amp/"
    const pathname = window.location.pathname

    if (pathname.indexOf(pathIdentifier) > -1) {
      console.log("amp has deployed")
    } else {
      import(`../assets/scss/main.scss`)
    }
  }, [])

  return (
    <>
      {!isAmp && <Loader variant="lottie" theme="light" />}
      <Navbar
        pathname={path}
        theme={theme}
        lang={lang}
        alt={alt}
        isAmp={isAmp}
      />
      <main>
        {children}
        <Footer lang={lang} isAmp={isAmp} />
      </main>
    </>
  )
}

export default Layout

import { gsap } from "gsap"

// Open menu
export const staggerBackgroundIn = (wrapper, background, content) => {
  gsap
    .timeline()
    // Make the outer layer visible
    .to(wrapper, { duration: 0, css: { display: "block" } })
    // Reveal the content and background
    .from([background, content], {
      duration: 0.8,
      height: 0,
      skewY: 2,
      transformOrigin: "top right",
      ease: "power3.inOut",
      stagger: {
        amount: 0.1,
      },
    })
}

// Close menu
export const staggerBackgroundOut = (content, background, wrapper) => {
  gsap
    .timeline()
    // Reveal close the content and background
    .to([content, background], {
      duration: 0.8,
      height: 0,
      ease: "power3.inOut",
      delay: 0.5,
      stagger: {
        amount: 0.07,
      },
    })
    // Make the outer layer invisible again
    .to(wrapper, { duration: 0, css: { display: "none" } })
    // Reset the width & height of the content and background
    .to([content, background], { duration: 0, width: "100%", height: "100%" })
}

// Stagger the content in
export const staggerContentIn = (...node) => {
  gsap.from([...node], {
    duration: 0.8,
    y: 50,
    delay: 0.2,
    ease: "power3.inOut",
    stagger: {
      amount: 0.3,
    },
  })
}

// Stagger the content out
export const staggerContentOut = (...node) => {
  gsap
    .timeline()
    .to([...node], {
      duration: 0.8,
      y: 50,
      delay: 0,
      ease: "power3.inOut",
      stagger: { amount: 0.3 },
    })
    .to([...node], { duration: 0, clearProps: "all" })
}

// Open submenu
export const revealFromTop = node => {
  gsap.from(node, {
    duration: 0.8,
    height: 0,
    transformOrigin: "top",
    ease: "power3.inOut",
  })
}

// Close submenu
export const revealCloseY = node => {
  gsap.to(node, {
    duration: 0.8,
    height: 0,
    ease: "power3.inOut",
  })
}

export const revealFromRight = node => {
  gsap.from(node, {
    duration: 0.8,
    width: 0,
    transformOrigin: "left",
    ease: "power3.inOut",
  })
}

export const revealCloseX = node => {
  gsap.to(node, {
    duration: 0.8,
    width: 0,
    ease: "power3.inOut",
  })
}

// // Hover on the link
// export const handleHover = e => {
//   gsap.to(e.target, {
//     duration: 0.3,
//     y: 3,
//     skewX: 4,
//     ease: "power1.inOut",
//   })
// }

// // Hover off the link
// export const handleHoverExit = e => {
//   gsap.to(e.target, {
//     duration: 0.3,
//     y: -3,
//     skewX: 0,
//     ease: "power1.inOut",
//   })
// }

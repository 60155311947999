import React from "react"
import PropTypes from "prop-types"
import ReactDatePicker from "react-datepicker"

export const DatePicker = ({
  floatingLabel,
  variant,
  label,
  labelClassName,
  theme,
  id,
  selected,
  value,
  onBlur,
  disabled,
  onChange,
  name,
  placeholder,
  wrapperClassName,
  className,
  touched,
  errors,
  setFieldValue,
}) => {
  const onDateChange = (selected, setFieldValue) =>
    setFieldValue(name, selected)

  const fieldVariant = () => {
    if (variant === "underline") {
      return "field-underline"
    } else {
      return "field-basic"
    }
  }

  return (
      <div
          className={`field ${fieldVariant()} 
          ${theme} 
          ${floatingLabel ? "field-floating" : ""} 
          ${
                touched && errors && touched[name] && errors[name] ? "is-invalid" : ""
            }
          ${className} `}
        >

          {label && (
              <label className={labelClassName} htmlFor={id}>
                {label}
              </label>
          )}
        <div
            className={`form-control-wrapper
            ${wrapperClassName}`}
        >

            <input
                id={id}
                name={name}
                type="date"
                placeholder={placeholder}
                className={`form-control`}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
            />
          {/*<ReactDatePicker*/}
          {/*  name={name}*/}
          {/*  className={`form-control ${className}`}*/}
          {/*  placeholderText={placeholder}*/}
          {/*  wrapperClassName={wrapperClassName}*/}
          {/*  selected={selected}*/}
          {/*  onChange={*/}
          {/*    setFieldValue ? value => onDateChange(value, setFieldValue) : onChange*/}
          {/*  }*/}
          {/*/>*/}

          {floatingLabel && (
              <label className={labelClassName} htmlFor={id}>
                {label}
              </label>
          )}
          <i className="el-underline" />
          {touched && errors && touched[name] && errors[name] && (
            <div className="validation-error">{errors[name]}</div>
          )}
        </div>
      </div>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
}

DatePicker.defaultProps = {
  name: "",
  placeholder: "",
  wrapperClassName: "",
  className: "",
}
